<template>
  <div />
</template>

<script>
import useBasicAuth from "@/auth/basic/useBasicAuth";
import { initialAbility } from "@/libs/acl/config";

export default {
  created() {
    useBasicAuth.logout();

    // Remove userData from localStorage
    localStorage.removeItem("foravilaGuestsUserData");

    // Reset ability
    this.$ability.update(initialAbility);

    // Redirect to login page
    this.$router.push({ name: "foravila-login" });
  }
};
</script>
